import { defineStore } from "pinia";
import {
  IActivity,
  IFile,
} from "@/modules/a.worksheets/stores/worksheetDataStore";

//OPEN_ENDED_TYPES=[(1,'PHOTO'),(2,'VIDEO'),(3,'AUDIO'),(4,'PDF'),(5,'TEXT'),(6,'DOCUMENT')]

interface IWorksheetAnswerStore {
  uuid: string; // userWorksheetStatus.uuid
  is_wks_assessed: boolean;
  is_wks_solved: boolean;
  n_problems_solved: number;

  //max_multiple_choices: number, // is defined in worksheet in close ended answer as n_options

  allow_peer_evaluation: boolean;
  assessment_type: EvaluationScale;
  qualitative_assessment_levels: number;
  max_num_files_answer: number;
  max_photo_files_answers: number;
  max_pdf_files_answers: number;

  max_file_size_mbytes: number;
  max_duration_video_answer_seconds: number;
  max_duration_audio_answer_seconds: number;
  selectedDisplayOption: ISolutionDisplayOption; // added

  show_new_solution: boolean,
  show_found_error: boolean,

  permissions: IPermissions;
  answers: Array<IAnswerWrapper>;
  reviewer_name: string | null;
}

interface IPermissions {
  canHavePeerAssess: boolean,
  canSolve: boolean;
  canSelfReview: boolean;
  canPeerReview: boolean;
  canTeacherReview: boolean;
  isPeerAssigned: boolean;
  showSelf: boolean,
  showPeer: boolean,
  showTeacher: boolean,
  showTeacherAnswers: boolean,
}

interface IAnswerWrapper {
  uuid: string;
  solved_at: number;
  problem: string;
  answer: IAnswer | null;
}
interface IAnswer {
  close_ended: IClosedEndedAnswer | null;
  open_ended: IOpenEndedAnswer | null;
}

interface IClosedEndedAnswer {
  student_answer: string;
}
interface IOpenEndedAnswer {
  uuid: string;
  input_type: InputType; //[(1,'PHOTO'),(2,'VIDEO'),(3,'AUDIO'),(4,'PDF'),(5,'TEXT'),(6,'DOCUMENT')]
  answer_files: Array<{
    uuid: string;
    order: number;
    file: IFile | null;
    textfield: string | null;
  }>;
  answer_assessment: Array<IAssessment>;
}

interface IAssessment {
  who_reviewed: Reviewer;
  evaluation_scale: EvaluationScale;
  grade: string;
  suffix: string;
}

enum InputType {
  PHOTO = 1,
  VIDEO = 2,
  AUDIO = 3,
  PDF = 4,
  TEXT = 5,
  DOCUMENT = 6,
}
enum Reviewer {
  SELF = 1,
  PEER = 2,
  TEACHER = 3,
}
enum EvaluationScale {
  QUANTITATIVE = 1,
  QUALITATIVE = 2,
}

type ISolutionDisplayOption = "Solution" | "Both" | "Question" | null;

const worksheetUserAnswerData: IWorksheetAnswerStore = {
  selectedDisplayOption: "Both",
  uuid: "73daa609-3aec-4fb9-9a90-ad1d72721d8e", //UUID of the database table UserWorksheetStatus
  is_wks_assessed: false,
  is_wks_solved: false,
  n_problems_solved: 0,

  //max_multiple_choices: 4, // max number of multiple choices if it is closed answer and multiple choices
  allow_peer_evaluation: true,
  assessment_type: 1,
  qualitative_assessment_levels: 4,
  max_num_files_answer: 2,
  max_pdf_files_answers: 1,
  max_photo_files_answers: 1,
  max_file_size_mbytes: 4,
  max_duration_video_answer_seconds: 180,
  max_duration_audio_answer_seconds: 180,
  show_new_solution: false,
  show_found_error: false,
  permissions: {
    canHavePeerAssess: true,
    canSolve: true,
    canSelfReview: true,
    canPeerReview: false,
    canTeacherReview: false,
    isPeerAssigned: false,
    showSelf: false,
    showPeer: false,
    showTeacher: false,
    showTeacherAnswers: false,
  },
  answers: [
    {
      uuid: "39a856ea-ba49-4449-a42d-eca244442eee", //not to be used at this moment
      solved_at: 1671009491384,
      problem: "8f346f4d-c5eb-409a-809a-8b0392a9bccf", //uuid of the problem FK to the Problem in worksheet data
      answer: {
        close_ended: null,
        open_ended: {
          uuid: "",
          input_type: 5, //[(1,'PHOTO'),(2,'VIDEO'),(3,'AUDIO'),(4,'PDF'),(5,'TEXT'),(6,'DOCUMENT')]
          answer_files: [
            {
              uuid: "afcae412-a9d6-4fe9-855f-e0eb71e85585",
              order: 0,
              file: { filename: "", filetype: "", filepath: ""},
              textfield: "Nao sei fazer",
            },
          ],
          answer_assessment: [
            {
              who_reviewed: 1, //1-self, 2-peer, 3-teacher
              evaluation_scale: 1, //1-quantitative, 2-qualitative
              grade: "10",
              suffix: "points",
            },
          ],
        },
      },
    },
    {
      uuid: "b01d9673-13d1-4876-b8dd-60c937dcc124", //not to be used at this moment
      solved_at: 1671009491384,
      problem: "6090583e-a06d-46dd-acc3-11877db5ff50", //uuid of the problem FK to the Problem in worksheet data
      answer: {
        close_ended: null,
        open_ended: {
          uuid: "",
          input_type: 1, //[(1,'PHOTO'),(2,'VIDEO'),(3,'AUDIO'),(4,'PDF'),(5,'TEXT'),(6,'DOCUMENT')]
          answer_files: [
            {
              uuid: "4519ec59-2469-4bfa-95fc-88b32371d52e",
              order: 2,
              file: {
                filename: "1672673234040.png",
                filetype: "png",
                filepath: "documents/",
              },
              textfield: "",
            },
            {
              uuid: "d83ce747-2c7d-4c8d-829f-736ab6b11560",
              order: 1,
              file: {
                filename: "1672673229344.png",
                filetype: "png",
                filepath: "documents/",
              },
              textfield: "",
            },
          ],
          answer_assessment: [
            {
              who_reviewed: 1, //1-self, 2-peer, 3-teacher
              evaluation_scale: 1, //1-quantitative, 2-qualitative
              grade: "1",
              suffix: "points",
            },
          ],
        },
      },
    },
    {
      uuid: "cd0f67c9-800e-4ada-b884-54f354846372", //not to be used at this moment
      problem: "67983085-91e8-4c06-b740-ef384005bbe6", //uuid of the problem FK to the Problem in worksheet data
      solved_at: 1671471623591, //date time as integer
      answer: {
        close_ended: null,
        open_ended: {
          uuid: "",
          input_type: 1, //[(1,'PHOTO'),(2,'VIDEO'),(3,'AUDIO'),(4,'PDF'),(5,'TEXT'),(6,'DOCUMENT')]
          answer_files: [
            {
              uuid: "018048ea-5e97-47da-a4ca-9220d6d0d8ae",
              file: {
                filename: "1673442191762_JsZ61CR.png",
                filetype: "png",
                filepath: "documents/",
              },
              order: 1,
              textfield: null,
            },
          ],
          answer_assessment: [
            {
              who_reviewed: 1, //1-self, 2-peer, 3-teacher
              evaluation_scale: 1, //1-quantitative, 2-qualitative
              grade: "2",
              suffix: "points",
            },
          ],
        },
      },
    },
    {
      uuid: "62f6a9e9-3539-4e1c-8985-9c16e7707ccc",
      problem: "e25fdb75-d0fd-48a7-993d-478cad4e9c64",
      solved_at: 1671480784153,
      answer: {
        close_ended: null,
        open_ended: {
          uuid: "",
          input_type: 1,
          answer_files: [
            {
              uuid: "4b3925f3-a0fb-4cde-bbae-627f9e60315e",
              file: {
                filename: "1671527762803.png",
                filetype: "png",
                filepath: "documents/",
              },
              order: 3,
              textfield: null,
            },
            {
              uuid: "1787ef8b-c9e7-47f3-b39b-0a37dd6a749c",
              file: {
                filename: "1671527748446.png",
                filetype: "png",
                filepath: "documents/",
              },
              order: 1,
              textfield: null,
            },
            {
              uuid: "d4407553-15d8-4594-b88b-6330550eb6d8",
              file: {
                filename: "1671527753052.png",
                filetype: "png",
                filepath: "documents/",
              },
              order: 2,
              textfield: null,
            },
          ],
          answer_assessment: [
            {
              who_reviewed: 1, //1-self, 2-peer, 3-teacher
              evaluation_scale: 1, //1-quantitative, 2-qualitative
              grade: "5",
              suffix: "points",
            },
          ],
        },
      },
    },
    {
      uuid: "f59b91a8-fb69-4412-b5ab-ae0cffb35a63",
      problem: "84823e84-70dc-4813-aaa4-92d3339ea96f",
      solved_at: 1671553113244,
      answer: {
        close_ended: null,
        open_ended: {
          uuid: "",
          input_type: 5,
          answer_files: [
            {
              uuid: "c0acd506-9c40-4ee8-8cd4-b2090c2d7ac4",
              file: { filename: "", filetype: "", filepath: "",},
              order: 0,
              textfield: "<p>My Solution</p>",
            },
          ],
          answer_assessment: [],
        },
      },
    },
  ],
  reviewer_name: null,
};

const useUserAnswersData = defineStore({
  id: "WksUserAnswerDataModule",
  state: (): IWorksheetAnswerStore => worksheetUserAnswerData,
  getters: {
    getAllAnswers: (state): Array<IAnswerWrapper> => {
      const completedActivities = state.answers.filter(
        (answer) => answer.answer !== undefined
      );
      return completedActivities ? completedActivities : [];
    },
    isAnswer:
      (state) =>
      (problemUUID: string): boolean => {
        const answer = state.answers.find(
          (answer) => answer.problem === problemUUID
        );
        const isCompleted = answer?.answer !== undefined;
        return isCompleted;
      },
    getAnswer:
      (state) =>
      (problemUUID: string): IAnswerWrapper | undefined => {
        return state.answers.find(
          (activity) => activity.problem === problemUUID
        );
      },
  },
  actions: {
    async worksheetStatus(wks_uuid: string, usr_uuid: string) {
      //const resp = getWorksheetStatus.get(wks_uuid, usr_uuid)
      return 0;
    },
    deleteAnswer(answerUUID: string) {
      this.answers = this.answers.filter(
        (answer) => answer.uuid === answerUUID
      );
    },
    setAnswer(wrappedAnswer: IAnswerWrapper) {
      this.answers.push(wrappedAnswer);
      console.log(
        "SET ANSWER: ",
        this.answers.find((answer) => answer.uuid === wrappedAnswer.uuid)
      );
    },
    reset() {
      this.answers = [];
      this.permissions = {
        canHavePeerAssess: true,
        canSolve: true,
        canSelfReview: true,
        canPeerReview: false,
        canTeacherReview: false,
        isPeerAssigned: false,
        showSelf: false,
        showPeer: false,
        showTeacher: false,
        showTeacherAnswers: false
      };
    },
  },
});

export {
  useUserAnswersData,
  IWorksheetAnswerStore,
  ISolutionDisplayOption,
  IAnswerWrapper,
  IPermissions,
  IAssessment,
  Reviewer,
  EvaluationScale,
  IOpenEndedAnswer,
  IClosedEndedAnswer,
};
