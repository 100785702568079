<template>
  <ion-app>
    <spinner :spinner-enabled="true"></spinner>
    <!-- <resize-modal v-if="isResizeModalOpen" message="Please set the zoom level to 100% to use the app properly"/> -->
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, toastController, IonToast } from "@ionic/vue";
import { computed, defineComponent, toRefs, watch, ref, nextTick } from "vue";
import { netListener, orientationListener } from "@/plugins/AppListeners";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { provideKeyboard } from "@/globals/keyboards/KeyboardManager";
import { provideWorksheet } from "@/globals/helpers/worksheetHandler";
import { provideModalManager } from "@/modules/a.worksheets/services/modalManager";
import Spinner from "@/globals/pages/Spinner.vue";
import {
  isLoading as isSpinner,
  spinnerManager,
} from "@/globals/pages/spinnerControl";
import router, { routeMetaData } from "@/router";
import { useDropdownUser } from "@/globals/stores/storeDropdownData";
import { useFooterData } from "@/globals/stores/storeFooterData";
import { useLearnUserFiltersStore } from "@/modules/3.learn/stores/learnUserFiltersStore";
import { useAccountDataUser } from "@/modules/5.account/stores/accountDataStore";
import { useFonts, usePageLayouts, useIcons } from "@/stores/pageLayoutsStore";
import { useGlobalWatchers } from "@/helpers/watchers.global";
import ResizeModal from "@/globals/pages/ResizeModal.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    Spinner,
  },
  setup() {
    provideWorksheet();
    provideKeyboard();
    provideModalManager();

    const deviceStatus = useDeviceStatus();
    const { isNetConnected } = toRefs(deviceStatus);
    const { isWeb } = toRefs(deviceStatus);
    const accountData = useAccountDataUser();
    const fontsStore = useFonts();
    const pageLayoutsStore = usePageLayouts();
    const iconStore = useIcons();

    const currentOrientation = ref(window.screen.orientation);
    function handleOrientationChange() {
      currentOrientation.value = window.screen.orientation;
      deviceStatus.setCurrentOrientation(currentOrientation.value.angle);
      console.log("ORIENTATION2:", currentOrientation.value);
      const t = document.querySelectorAll("canvas");
      console.log("CANVAS", t);
    }
    window.addEventListener("orientationchange", handleOrientationChange);
    console.log("ORIENTATION ", window.screen);

    //console.warn("process.env: ", process.env);
    if (process.env.NODE_ENV === "development") {
      console.log("Development mode"); // Code to run in development mode
      deviceStatus.mode = "development";
    } else if (process.env.NODE_ENV === "production") {
      console.log("production mode"); // Code to run in production mode
      deviceStatus.mode = "production";
    } else {
      console.log("unknown mode");
    }

    // DISABLED TO TEST OFFLINE Worksheets

    const footerData = useFooterData();
    const dropdown = useDropdownUser();
    useGlobalWatchers();
    let previousKind = dropdown.getKind;

    router.beforeEach(async (to, from, next) => {
      if (!(to.name === "LoadingPage" || from.name === "LoadingPage")) {
        spinnerManager.registerTask("page route change");
      }

      const normalizedPath = to.path.startsWith('/') ? to.path.slice(1) : to.path;
      const toMeta = to.meta as routeMetaData;
      const currentKind = dropdown.getKind;
      const footerRoutesUser = footerData.footerRoutesUser;
      const footerRoutesClass = footerData.footerRoutesClass;

      const isCommonRoute = footerRoutesUser.includes(normalizedPath) && footerRoutesClass.includes(normalizedPath);
      console.log("COMUM", isCommonRoute);

      if (toMeta && toMeta.footerIndex !== undefined) {
        if (previousKind !== currentKind) {
          if (currentKind === 'classroom') {
            footerData.selectButtonClass(footerData.footerSelectedButtonClass);
            const targetRoute = footerRoutesClass[footerData.footerSelectedButtonClass];
            next({ path: `/${targetRoute}` });
          } else {
            footerData.selectButtonUser(footerData.footerSelectedButtonUser);
            const targetRoute = footerRoutesUser[footerData.footerSelectedButtonUser];
            next({ path: `/${targetRoute}` });
          }
        } else {
          if (isCommonRoute) {
            footerData.selectButtonClass(toMeta.footerIndex);
            footerData.selectButtonUser(toMeta.footerIndex);
          } else {
            if (currentKind === 'classroom' && footerRoutesClass.includes(normalizedPath)) {
              footerData.selectButtonClass(toMeta.footerIndex);
            } else if (currentKind !== 'classroom' && footerRoutesUser.includes(normalizedPath)) {
              footerData.selectButtonUser(toMeta.footerIndex);
            }
          }
          next();
        }
      } else {
        next();
      }
      previousKind = currentKind;
    });




    const learnUserFilterStore = useLearnUserFiltersStore();
    router.beforeEach(async (to, from, next) => {
      if (from.path === "/worksheet" && to.path === "/learn") {
        console.warn("WorksheetUserPage - route before each");
        await learnUserFilterStore.update(4);
        next();
      } else {
        next();
      }
    });

    router.afterEach((to, from, next) => {
      spinnerManager.unregisterTask("page route change");
    });

    const isResizeModalOpen = ref(false);
    const setResizeModalOpen = (v: boolean) => (isResizeModalOpen.value = v);
    router.afterEach(async (to, from) => {
      const pZoom = window.visualViewport?.scale;
      console.log("pinch zoom level", pZoom);
      //      if (pZoom && pZoom !== 1) {
      //        setResizeModalOpen(true);
      //        setTimeout(() => setResizeModalOpen(false), 5000)
      //      }
    });

    watch(
      computed(() => dropdown.getKind),
      (newKind) => {
        const meta = router.currentRoute.value.meta as routeMetaData;
        const isKindPermitted =
          meta.kind === undefined ||
          (meta && meta.kind.some((v) => v === newKind));
        if (!isKindPermitted) {
          const newRoute = router.getRoutes().find((route) => {
            const routeMeta = route.meta as routeMetaData;
            return (
              routeMeta.footerIndex === meta.footerIndex &&
              routeMeta.kind?.some((v) => v === newKind)
            );
          });

          if (newRoute) {
            router.push(newRoute.path);
          } else {
            router.push("homepage");
          }
        }
      }
    );

    return {
      deviceStatus,
      isNetConnected,
      isSpinner,
      spinnerManager,
      isWeb,
      setResizeModalOpen,
      isResizeModalOpen,
    };
  },

  async created() {
    await netListener();
    await orientationListener();
    //-------------------
    // include other listeners like HardBackButon, Orientation, Localization
    //------------------
    // if (!this.isWeb) {
    //   console.log("isWeb", window.screen.orientation);
    //   try {
    //     window.screen.orientation.lock("portrait");
    //   } catch (error) {
    //     console.log("ORIENTATION.LOCK error:", error);
    //   }
    // }
    // const netStatus = await Network.getStatus();
    // console.log("isNet:", netStatus);
    // if (!netStatus.connected) {
    //   //this.deviceStatus.isNetConnected = false;
    //   console.log("HERE", this.isNetConnected);
    //   this.presentAlert();
    // }
  },
});
</script>
<style>
html {
  /*font-size: calc(7.566px + 1.9457vw);
  /*font-size: clamp(16px, calc(1em + 1vw), 28px);*/
}

html.mobile {
  font-size: calc(12.566px + 1.9457vw);
}

html.web {
  font-size: max(1.5rem, 12.566px + 0.75vw);
}
</style>
@/globals/pages/spinnerControl @media screen and (min-width: 600px) { html {
font-size: 24px; /* even larger font size for large screens */ } html.web {
font-size: 10px; } } @media screen and (min-width: 800px) { html { font-size:
26px; /* even larger font size for large screens */ } html.web { font-size:
11px; } } @media screen and (min-width: 1000px) { html { font-size: 28px; /*
even larger font size for large screens */ } html.web { font-size: 14px; } }
@media screen and (min-width: 1200px) { html { font-size: 30px; /* even larger
font size for large screens */ } html.web { font-size: 16px; } } @media screen
and (min-width: 1400px) { html { font-size: 32px; /* even larger font size for
large screens */ } html.web { font-size: 18px; } } @media screen and (min-width:
1600px) { html { font-size: 34px; /* even larger font size for large screens */
} html.web { font-size: 20px; } } @media screen and (min-width: 1800px) { html {
font-size: 20px; /* even larger font size for large screens */ } html.web {
font-size: 22px; } } @media screen and (min-width: 2000px) { html { font-size:
22px; /* even larger font size for large screens */ } html.web { font-size:
24px; } } @media screen and (min-width: 2200px) { html { font-size: 22px; /*
even larger font size for large screens */ } html.web { font-size: 26px; } }
@media screen and (min-width: 2400px) { html.web { font-size: calc(26px +
0.3vw); } }
