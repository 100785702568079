<template>
    <div class="fullpage" :class="{ large: isLarge }" :style="{ ...titleFont, ...descriptionFont }">
        <div class="dim">
            <div v-if="!showPdfContainer" class="pop-up-mask-container">
                <img v-if="showArrow" :src="getCloseIcon" id="arrow-back" @click="close" />

                <div class="bundle">
                    <div class="icon">
                        <slot name="icon"></slot>
                    </div>
                    <slot name="title"></slot>
                    <slot name="description"></slot>
                </div>

                <slot name="button"></slot>
            </div>
            <div v-else class="pop-up-mask-container">
                <img v-if="showArrow" :src="getCloseIcon" id="arrow-back" @click="close" />

                <div class="bundlepdf">
                    <slot name="pdfContainer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>




<script setup lang="ts">
import { onMounted, ref, computed, toRefs, watch } from 'vue';
import { getIcon } from '@/helpers/api';
import { modalController } from '@ionic/vue';
import { getFont, IFont } from '@/helpers/fonts';
import { useDeviceStatus } from '@/stores/deviceStatus';

const { isWeb } = toRefs(useDeviceStatus())
const arrow_left = ref<string>()
const x_icon = ref<string>()

const emit = defineEmits(['close']);

const props = defineProps({
    showArrow: {
        type: Boolean,
        default: true
    },
    useEmitClose: {
        type: Boolean,
        default: false
    },
    showPdfContainer: {
        type: Boolean,
        default: false
    },
    isLarge: {
        type: Boolean,
        default: false,
    },
})

onMounted(async () => {
    arrow_left.value = await getIcon("1010", "left_arrow");
    x_icon.value = await getIcon("1010", "close");
})

function close() {
    if (props.useEmitClose) {
        emit('close');
    } else {
        return modalController.dismiss(null, 'cancel');
    }
}

const nobo30 = ref<IFont | undefined>()
const nore14 = ref<IFont | undefined>()

onMounted(async () => {
    nobo30.value = await getFont('nobo30')
    nore14.value = await getFont('nore14')
})

const titleFont = computed(() => {
    return {
        '--title-font-family': nobo30.value?.['font-family'],
        '--title-font-size': nobo30.value?.['font-size'],
        '--title-font-transform': nobo30.value?.['text-transform'],
        '--title-font-weight': nobo30.value?.['font-weight']
    }
})
const descriptionFont = computed(() => {
    return {
        '--description-font-family': nore14.value?.['font-family'],
        '--description-font-size': nore14.value?.['font-size'],
        '--description-font-transform': nore14.value?.['text-transform'],
        '--description-font-weight': nore14.value?.['font-weight']
    }
})

const getCloseIcon = computed(() => isWeb.value ? x_icon.value : arrow_left.value)

</script>

<style scoped>
.fullpage {
    z-index: 102;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
}

.fullpage.large {
    width: 100%;
    height: auto;
}

.dim {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.web .dim {
    background-color: transparent;
    display: contents;
}

.pop-up-mask-container {
    z-index: 102;
    background-color: white;
    width: 100%;
    height: 90%;
    position: relative;

    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 34px 30px;
}

.web .pop-up-mask-container {
    width: 100%;
    height: 100%;
}

.bundlepdf {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 5%;
}

.bundle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
}

.web .bundle {
    width: 100%;
    margin-top: 8%;
}

.icon {
    width: 60px;
    height: 60px;
}

.icon>* {
    /* position: absolute;*/
    left: 31px;
    top: 32px;
}

#arrow-back {
    position: absolute;
    left: 31px;
    top: 32px;

    width: 6%;
    cursor: pointer;
}

.web #arrow-back {
    left: auto;
    right: 31px;
    top: 32px;
}

.pdf-container {
    margin-top: 5%;
    width: 100%;
    height: 100%;
}



</style>