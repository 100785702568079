<template>
  <ion-page>
    <ion-content>
      <PopUpMask :style="titleFont" :showArrow="false">
        <template #icon>
          <img :src="wifiIcon" />
        </template>

        <template #title>
          <h2 id="title">{{ layout?.labels.title }}</h2>
        </template>

        <template #description>
          <div class="description-wrapper">
            <span
              v-for="(line, key) in layout?.labels.lines"
              :key="key"
              v-html="line"
            >
            </span>
          </div>
        </template>

        <template #button>
          <!-- <div class="spinner-wrapper">
            <ion-spinner name="lines" />
          </div> -->
          <IonButton @click="handleClick">
            {{ layout?.labels.button }}
          </IonButton>
        </template>
      </PopUpMask>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { modalController, IonContent, IonSpinner, IonPage, IonButton } from "@ionic/vue";
import { computed, onBeforeMount, ref, watch, defineComponent } from "vue";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { IPageLayout, getComputedPageLayout, getIcon } from "@/helpers/api";
import PopUpMask from "@/modules/a.worksheets/views/components/feedback/PopUpMask.vue";
import { getFont, IFont } from "@/helpers/fonts";
import router from "@/router";

interface _9001 extends IPageLayout {
  labels: {
    lines: string[];
    title: string;
    button: string;
  };
}

export default defineComponent({
  name: "NetworkError",
  components: {
    IonContent,
    IonPage,
    PopUpMask,
    IonButton,
  },
  setup() {
    const deviceStatus = useDeviceStatus();
    const isConnected = computed(() => deviceStatus.isNetConnected);

    watch(isConnected, (currentValue, oldValue) => {
      if (isConnected.value) return modalController.dismiss(null, "confirm");
    });

    async function handleClick(){
      await modalController.dismiss();
      setTimeout(() => { router.replace("/")
      window.location.replace("/")}, 1000)  
    }

    const titleFontStyle = ref<IFont>();
    const layout = getComputedPageLayout<_9001>("9001");
    const wifiIcon = ref<string>();
    onBeforeMount(async () => {
      titleFontStyle.value = await getFont("nobo25");
      wifiIcon.value = await getIcon("9001", "wifi");
    });

    const titleFont = computed(() => {
      return {
        "--title-font-family": titleFontStyle.value?.["font-family"],
        "--title-font-size": titleFontStyle.value?.["font-size"],
        "--title-font-transform": titleFontStyle.value?.["text-transform"],
        "--title-font-weight": titleFontStyle.value?.["font-weight"],
      };
    });

    return {
      titleFont,
      layout,
      wifiIcon,
      titleFontStyle,
      handleClick
    };
  },
});
</script>

<style scoped>
ion-content::part(background) {
  background-color: transparent;
}

#title {
  font-family: var(--title-font-family);
  font-size: var(--title-font-size);
  text-transform: var(--title-font-transform);
  font-weight: var(--title-font-weight);
  text-align: center;
}
img {
  width: 100%;
}
.description-wrapper {
  font-family: var(--description-font-family);
  font-size: var(--description-font-size);
  text-transform: var(--description-font-transform);
  font-weight: var(--description-font-weight);
  color: var(--milage-color-default-body);
}
.description-wrapper span {
  display: block;
  text-align: center;
}

ion-spinner {
  width: 3rem;
  height: 3rem;
}
</style>
