<template>
  <div @click="activate" class="lazy-video">
    <video
      :src="videoSrc + '#t=0.1'"
      controls
      id="video"
      ref="videoElement"
      @load="$emit('loaded')"
      @loadeddata="$emit('loaded')"
      :poster="thumbnailSrc"
      preload="metadata"
      controlsList="nodownload"
    ></video>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
  watch,
} from "vue";
import { getIcon } from "@/helpers/api";
import { useRoute } from "vue-router";
import Hls from "hls.js";
import { pauseAllVideos } from "../helpers/worksheetHandler";
/// !important
/// The hsl module is probably not working at the moment and when done correctly the video doesnt load.

const props = defineProps({
  videoSrc: { type: String, default: () => "" },
  thumbnailSrc: { type: String },
});

const router = useRoute();
const emit = defineEmits(["loaded"]);
const isActivated = ref(false);

const videoElement = ref<HTMLVideoElement | null>(null);
// watch(videoElement, (el) => {
//   if(!el) return;

//   if (Hls.isSupported()) {
//     const hls = new Hls();
//     hls.loadSource(props.videoSrc);
//     hls.attachMedia(el);
//   }else if (el.canPlayType("application/vnd.apple.mpegurl")) {
//     el.src = props.videoSrc;
//   }
// })

onBeforeMount(() => {
  if(!videoElement.value) return;

  const video = videoElement.value;
  console.log("video: ", video)
  if (Hls.isSupported()) {
    const hls = new Hls();
    hls.loadSource(props.videoSrc);
    hls.attachMedia(video);
  }else if (video.canPlayType("application/vnd.apple.mpegurl")) {
    video.src = props.videoSrc;
  }
});

const activate = () => play();
const deactivate = () => stop();

function play() {
  pauseAllVideos();
  videoElement.value?.play();
  isActivated.value = true;
}
function stop() {
  isActivated.value = false;
}

// onBeforeRouteLeave function was not working without a console.log statement
watch(
  computed(() => router.fullPath),
  (newPath, oldPath) => {
    if (newPath !== oldPath) {
      deactivate();
    }
  }
);

onMounted(() => {
  const videoElements = document.querySelectorAll("video");

  videoElements.forEach((video) => {
    video.addEventListener("play", () => {
      videoElements.forEach((otherVideo) => {
        if (otherVideo !== video && !otherVideo.paused) {
          otherVideo.pause();
        }
      });
    });
  });
});


const playIcon = ref<string>();
onMounted(async () => {
  playIcon.value = await getIcon("1602", "play");
  //emit("loaded")
});
onUpdated(() => {
  //emit("loaded")
});

// let player: Plyr | undefined;
// watch(videoElement, () => {
//   if (videoElement.value) {
//     // player = new Plyr(videoElement.value, {
//     //   disableContextMenu: false,
//     //   fullscreen: {container: undefined, iosNative: true}
//     // })
//   }
// });

function loaded() {
  emit("loaded");
}

onBeforeUnmount(() => {
  //player?.destroy();
});

defineExpose({
  activate,
  deactivate,
});
</script>

<style scoped>
.lazy-video {
  width: 100%;
  height: 100%; /* Ensure that the container takes the full height */
  position: relative;
  overflow: hidden; /* Hide any overflow if the video exceeds the container */
}
.lazy-video * {
  width: 100%;
  height: 100%;
}
.lazy-video video {
  width: 100%;
  height: 100%; /* Set the video height to 100% */
  display: block; /* Ensures that there's no extra space around the video */
}
#thumbnail {
  height: 100%;
  width: 100%;
}

.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
.round-border {
  padding: 1rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  width: auto;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.round-border img {
  margin-left: 0.25rem;
}
</style>

<style>
.plyr--video {
  height: 100%;
}
</style>
