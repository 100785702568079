<template>
  <div :class="['controls', { 'center-layout': centerButtons }]">
    <!-- Grid 1 -->
    <div class="col" v-if="!centerButtons">
    </div>

    <!-- Grid 2 -->
    <div :class="['col', 'col2', { 'center-icons': centerButtons }]">
      <div v-if="areMultiplePages" class="page">
        <img :src="leftArrow" @click="$emit('prev')" :style="{ opacity: isLeftArrowGray ? 0.3 : 1 }" />
        <img :src="rightArrow" @click="$emit('next')" :style="{ opacity: isRightArrowGray ? 0.3 : 1 }" />
        <div class="pages-count">
          <span :style="nore12">{{ currentPage }} / {{ pageCount }}</span>
        </div>
      </div>

      <!-- Condicionalmente exibe os ícones na coluna do meio -->
      <div v-if="centerButtons && (isDownload || isDelete || isMove)" class="icons">
        <img v-if="isMove" :src="expand" @click="$emit('expand')" class="expand-icon" />
        <img v-if="isDelete" :src="binIcon" @click="$emit('delete')" />
        <img v-if="isDownload" :src="downloadIcon" @click="$emit('download')" />
      </div>
    </div>

    <!-- Grid 3 -->
    <div class="col" v-if="!centerButtons">
      <div v-if="isDownload || isDelete || isWeb || isMove" class="icons">
        <img v-if="isMove" :src="expand" @click="$emit('expand')" class="expand-icon" />
        <img v-if="isDelete" :src="binIcon" @click="$emit('delete')" />
        <img v-if="isDownload" :src="downloadIcon" @click="$emit('download')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, StyleValue, toRefs, watch } from "vue";
import { useControls } from "@/globals/components/PdfViewer/useControls";
import { getFont } from "@/helpers/fonts";
import { getIcon } from "@/helpers/api";
import { useDeviceStatus } from "@/stores/deviceStatus";

const props = defineProps({
  isMove: { type: Boolean, default: false },
  isDownload: { type: Boolean, default: false },
  isDelete: { type: Boolean, default: false },
  page: { type: Number, default: 1 },
  pageCount: { type: Number, default: 1 },
  centerButtons: { type: Boolean, default: false },
});


defineEmits<{
  (event: "delete"): void;
  (event: "download"): void;
  (event: "next"): void;
  (event: "prev"): void;
  (event: 'expand'): void;
}>();

const binIcon = ref<string>();
const downloadIcon = ref<string>();

const areMultiplePages = computed(() => props.pageCount > 1);

const nore12 = ref<StyleValue>();
const leftArrow = ref<string>();
const rightArrow = ref<string>();
const expand = ref<string>();
const { isWeb } = toRefs(useDeviceStatus());
onMounted(async () => {
  nore12.value = (await getFont("nore12")) as StyleValue;
  expand.value = await getIcon("1602", "expand");

  leftArrow.value = await getIcon("1602", "previous_page");
  rightArrow.value = await getIcon("1602", "next_page");
  binIcon.value = await getIcon("2105", "bin");
  downloadIcon.value = await getIcon("2105", "download");
});

const isRightArrowGray = computed(() => props.page === props.pageCount);
const isLeftArrowGray = computed(() => props.page === 1);
computed(() => props.page === 1);
const currentPage = ref(props.page);

watch(
  () => props.page,
  (newPage) => {
    currentPage.value = newPage;
  }
);


</script>

<style scoped>
/* Pdf Controls */
.controls {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  align-items: center;
  width: 100%;
  height: 1.5rem;
  padding: 0.25rem
}

.web .controls {
  height: 2rem;
}

.controls .icons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  height: 100%;
  cursor: pointer;
}

.controls img {
  height: 100%;
}

.controls .page {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 12px;
  height: 100%;
}

.controls .page>span {
  cursor: pointer;
}

.col {
  height: 100%;
  min-height: 100%;
}

.col2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages-count {
  white-space: nowrap;
  color: black;
}

.center-layout {
  grid-template-columns: 1fr;
}


.center-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ------- */
</style>
