import { defineStore } from "pinia";
import requestService from "@/services/requests";
// import { IDropdown as IDropdownOriginal } from "@/globals/components/DropDownSlider.vue";
import { getComputedPageLayout } from "@/helpers/api";
import { ComputedRef, ref, watch } from "vue";
//import { IWksElement } from "@/modules/3.learn/views/components/WksElements.vue";

interface IDropdown {
  title: string;
  name: string;
  selectionRequired: boolean;
  options: Array<string>;
  values: Array<string | number>;
  selected: number | Array<boolean> | undefined;
}

interface IWksElement {
  name: string;
  difficulty_level: number;
  icon?: string;
  wksUUID: string;
  solved_ratio: number;
  isDraft: boolean;
  isEditable: boolean;
  routers: Array<string>;
}

interface ISimpleEntry {
  id: string;
  name: string;
}

interface IWorksheet {
  uuid: string;
  wks_uuid: string;
  name: string;
  difficulty_level: number;
  router: string[];
  is_downloadable: boolean;
  isLocked: boolean;
  is_assignable: boolean;
  isDraft: boolean;
  isEditable: boolean;
  solved_ratio: number;
  wks_icon: string;
  can_assess: boolean;
}

interface IResponse {
  data: Array<Array<ISimpleEntry>>;
  filters: Array<string | null>;
  title: string[];
  worksheets: IWorksheet[];
  showAssessBtn: boolean;
  floatButtonsShow: number[] | undefined;
}

type SearchChangedPos = 0 | 1 | 2 | 3 | 4 | 5;

interface ILearnUserFiltersStore {
  searchChangedPos: SearchChangedPos;
  isSolve: boolean;
  filters: Array<string | null>;
  filterData: Array<Array<ISimpleEntry>>;
  titles: string[];
  worksheets: IWorksheet[];
  showAssessBtn: boolean;
  floatButtonsShow: number[];
  userUuid: string;
  layout: ComputedRef<_1301 | undefined>;
}

interface _1301 {
  labels: {
    btnClear: string;
    btnApply: string;
    titles: string[];
    filters: string[];
  };
  commands: { selectionRequired: boolean[] };
}

// user-worksheets-solve-assess
const useLearnUserFiltersStore = defineStore({
  id: "LearnUserFiltersStore",
  state: (): ILearnUserFiltersStore => ({
    searchChangedPos: 5,
    isSolve: true,
    filters: [],
    filterData: [],
    titles: [],
    worksheets: [],
    showAssessBtn: false,
    floatButtonsShow: [],
    layout: getComputedPageLayout<_1301>("1301"),
    userUuid: "",
  }),
  actions: {
    async initialize(uuid: string): Promise<IResponse> {
      const response = await requestService.getWithParams<IResponse>(
        "user-worksheets-solve-assess-download",
        {
          is_solve: true,
          uuid: uuid,
        }
      );
      this.filters = response.data.filters;
      this.filterData = response.data.data;
      this.titles = response.data.title;
      this.worksheets = response.data.worksheets;
      this.showAssessBtn = response.data.showAssessBtn;
      this.floatButtonsShow = response.data.floatButtonsShow ?? [];
      this.userUuid = uuid;

      return response.data;
    },
    async update(searchChangedPos: number | null = null, isDownload: boolean = false) {
      const response = await requestService.post<IResponse>(
        "user-worksheets-solve-assess-download",
        {
          search_changed_pos: searchChangedPos ?? this.searchChangedPos,
          is_solve: isDownload ? true : this.isSolve,
          filters: this.filters,
          uuid: this.userUuid,
          is_download: isDownload
        }
      );

      this.filterData = response.data.data;
      this.titles = response.data.title;
      this.worksheets = response.data.worksheets;
      this.showAssessBtn = response.data.showAssessBtn;
      this.floatButtonsShow = response.data.floatButtonsShow ?? [];

      this.filters = response.data.filters
    },
  },
  getters: {
    getWksElements: (state): IWksElement[] => {
        const worksheetElements: IWksElement[] = [];

        for (let i = 0; i < state.worksheets.length; i++) {
          const wks = state.worksheets[i];
          const routers = wks.router;
          const isDraft = wks.isDraft;
          const isEditable = wks.isEditable;
          const solved_ratio = wks.solved_ratio;
          const icon = wks.wks_icon;
          const difficulty_level = wks.difficulty_level;

          worksheetElements.push({
              name: wks.name,
              difficulty_level: difficulty_level,
              icon: icon,
              wksUUID: wks.uuid,
              solved_ratio: solved_ratio,
              isDraft: isDraft,
              isEditable: isEditable,
              routers: routers,
          })
        }

      return worksheetElements;
    },
    getDropdowns: (state): IDropdown[] => {
      const dropdowns: IDropdown[] = [];

      for (let i = 0; i < state.filterData.length; i++) {
        const filterUUID = state.filters[i] ?? "";
        const index = state.filterData[i].findIndex((e) => e.id === filterUUID);

        const title = state.layout?.labels.titles[i] ?? "";
        const name = state.layout?.labels.filters[i] ?? "";
        const filterData = state.filterData[i];
        const selectionRequired = state.layout?.commands.selectionRequired[i] ?? false;

        dropdowns.push({
            title: title,
            name: name,
            selectionRequired: selectionRequired,
            options: filterData.map((e) => e.name),
            values: filterData.map((e) => e.id),
            selected: selectionRequired ? index : undefined,
        })
      }

      return dropdowns;
    },
    getLines: (state): string[] => {
      const lines: string[] = [];
      for (let i = 0; i < state.titles.length; i++) {
        lines.push(state.titles[i]);
      }
      return lines;
    },
    getCourse: (state) => {
      const courseUUID = state.filters[0] ?? "";
      if (!Array.isArray(state.filterData[0])) return undefined;
      const index = state.filterData[0].findIndex((e) => e.id === courseUUID);
      if (index === -1) return undefined;
      return state.filterData[0][index].name;
    },
    getChapter: (state) => {
      const chapterUUID = state.filters[1] ?? "";
      if (!Array.isArray(state.filterData[1])) return undefined;
      const index = state.filterData[1].findIndex((e) => e.id === chapterUUID);
      if (index === -1) return undefined;
      return state.filterData[1][index].name;
    },
    getSubChapter: (state) => {
      const subChapterUUID = state.filters[2] ?? "";
      if (!Array.isArray(state.filterData[2])) return undefined;
      const index = state.filterData[2].findIndex((e) => e.id === subChapterUUID);
      if (index === -1) return undefined;
      return state.filterData[2][index].name;
    }
  },
});

/*
interface IWksElement {
  name: string;
  difficulty_level: number;
  icon?: string;
  wksUUID: string;
  solved_ratio: number;
  isDraft: boolean;
  isEditable: boolean;
  routers: Array<string>;
}
*/

export { useLearnUserFiltersStore, SearchChangedPos };