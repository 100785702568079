import { ref, InjectionKey, provide, inject } from "vue"
import router from "@/router"

enum KeyboardType {
    QUALITATIVE = 0,
    QUANTITATIVE = 1
}

class KeyboardManagerClass {
    isOpen = ref(false)
    type = ref<KeyboardType | undefined>()
    value = ref<string>('');
    
    openQualitativeKeyboard = () => {
        this.isOpen.value = true
        this.type.value = KeyboardType.QUALITATIVE
    }

    openQuantitativeKeyboard = () => {
        this.isOpen.value = true
        this.type.value = KeyboardType.QUANTITATIVE
    }

    closeKeyboard = () => {
        this.isOpen.value = false
        this.type.value = undefined
    }

    constructor(){
        router.beforeEach(() => {
            this.closeKeyboard()
            return true
        })
    }
}


const KeyboardManagerInstance = new KeyboardManagerClass();

// Define the injection key
const KeyboardKey: InjectionKey<KeyboardManagerClass> = Symbol('KeyboardManager');
// Function to provide the KeyboardManager instance
export function provideKeyboard() {
    provide(KeyboardKey, KeyboardManagerInstance);
}

// Function to inject the KeyboardManager instance
export function useKeyboardManager() {
    const injectedKeyboard = inject(KeyboardKey);
    if (!injectedKeyboard) {
        throw new Error('No Keyboard instance provided!');
    }
    return injectedKeyboard;
}